<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- Container wrapper -->
  <div class="container">
    <!-- Toggle button -->
    <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars"></i>
    </button>
    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Navbar brand -->
      <a class="navbar-brand mt-2 mt-lg-0" routerLink="/">
        <img src="../../../assets/images/bravo-favicon.png" height="30" alt="Product" loading="lazy" />
      </a>
      <!-- Left links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item"><a class="nav-link" routerLink="/">Home</a></li>
        <li class='nav-item dropdown'>
          <a class='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown'
            aria-expanded='false'>Product</a>
          <ul class='dropdown-menu' aria-labelledby='navbarDropdown'>
            <li><a class='dropdown-item' routerLink="/product">Product 1</a></li>
            <li><a class='dropdown-item' routerLink="/">Product 2</a></li>
            <li><a class='dropdown-item' routerLink="/">Product 3</a></li>
            <li>
              <hr class='dropdown-divider' />
            </li>
            <li><a class='dropdown-item' routerLink="/">All</a></li>
          </ul>
        </li>
        <li class='nav-item dropdown'>
          <a class='nav-link dropdown-toggle' routerLink="/pictures" id='navbarDropdown' role='button'
            data-bs-toggle='dropdown' aria-expanded='false'>Pictures</a>
          <ul class='dropdown-menu' aria-labelledby='navbarDropdown'>
            <li><a class='dropdown-item' routerLink="/pictures/dog">Dog</a></li>
            <li><a class='dropdown-item disabled' routerLink="/">Cat</a></li>
            <li><a class='dropdown-item disabled' routerLink="/">Tree</a></li>
            <li>
              <hr class='dropdown-divider' />
            </li>
            <li><a class='dropdown-item' routerLink="/pictures">All</a></li>
          </ul>
        </li>
        <li class="nav-item"><a (click)="onExecute()" class="nav-link">Execute</a></li>
        <li class="nav-item"><a routerLink="/elearning" class="nav-link">Elearning</a></li>
        <li class="nav-item"><a class="nav-link disabled" routerLink="/">Disabled</a></li>
      </ul>
      <!-- Left links -->
    </div>
    <!-- Collapsible wrapper -->
    <!-- Right elements -->
    <div *ngIf="isLogin; else loggin" class="d-flex align-items-center">
      <!-- Icon -->
      <a class="text-reset me-3"><i class="fas fa-shopping-cart"></i></a>
      <!-- Notifications -->
      <a class="text-reset me-3 dropdown-toggle hidden-arrow" id="navbarDropdownMenuLink" role="button"
        data-mdb-toggle="dropdown" aria-expanded="false">
        <i class="fas fa-bell"></i>
        <span class="badge rounded-pill badge-notification bg-danger">3</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
        <li><a class="dropdown-item" routerLink="/">Product 1</a></li>
        <li><a class="dropdown-item" routerLink="/">Product 2</a></li>
        <li><a class="dropdown-item" routerLink="/">Product 3</a></li>
      </ul>
      <!-- Avatar -->
      <a class="dropdown-toggle d-flex align-items-center hidden-arrow" id="navbarDropdownMenuLink" role="button"
        data-mdb-toggle="dropdown" aria-expanded="false">
        <img src="../../../assets/images/bravo-favicon.png" class="rounded-circle" height="25" alt="user"
          loading="lazy" />
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
        <li><a class="dropdown-item" routerLink="/">Profile</a></li>
        <li><a class="dropdown-item" routerLink="/">Product</a></li>
        <li><a class="dropdown-item" routerLink="/">Setting</a></li>
        <li>
          <hr class='dropdown-divider' />
        </li>
        <li><a class="dropdown-item" (click)="onLogout()">Đăng xuất</a></li>
      </ul>
    </div>
    <ng-template #loggin>
      <div class="d-flex justify-content-end">
        <button [disabled]="true" class="btn btn-light me-2">Đăng ký</button>
        <button (click)="onLogin()" type="button" class="btn btn-success">Đăng nhập</button>
      </div>
    </ng-template>
    <!-- Right elements -->
  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->
