<div class="row g-4">

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 1</h5>
        <p class="card-text">Interval: {{ valueSubscription1 }}</p>
        <button (click)="onUnsubscribe1()" type="button" class="btn btn-danger">Hủy</button>
      </div>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 2</h5>
        <p class="card-text">Interval (async): {{ subscription2 | async }}</p>
        <button (click)="onUnsubscribe2()" type="button" class="btn btn-danger" disabled>Hủy</button>
      </div>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 3</h5>
        <p class="card-text">Take: {{ valueSubscription3 }}</p>
        <button (click)="onUnsubscribe3()" type="button" class="btn btn-danger" disabled>Hủy</button>
      </div>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 4</h5>
        <p class="card-text">First: {{ valueSubscription4 }}</p>
        <button (click)="onUnsubscribe4()" type="button" class="btn btn-danger" disabled>Hủy</button>
      </div>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 5</h5>
        <p class="card-text">takeUntil (notifier): {{ valueSubscription5 }}</p>
        <button (click)="onUnsubscribe5()" type="button" class="btn btn-danger">Hủy</button>
      </div>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 6</h5>
        <p class="card-text">Observable Array: console</p>
        <button (click)="onUnsubscribe6()" type="button" class="btn btn-danger">Hủy</button>
      </div>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Subscription 7</h5>
        <p class="card-text">Subscription: console</p>
        <button (click)="onUnsubscribe7()" type="button" class="btn btn-danger">Hủy</button>
      </div>
    </div>
  </div>

</div>
