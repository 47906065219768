<div class='mt-4'>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light text-muted">
    <!-- Section: Social media -->
    <section class="container d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Stay connected with us on social networks!</span>
      </div>
      <!-- Left -->

      <!-- Right -->
      <div>
        <a routerLink="" class="me-4 text-reset"><i class="fab fa-facebook-f"></i></a>
        <a routerLink="" class="me-4 text-reset"><i class="fab fa-twitter"></i></a>
        <a routerLink="" class="me-4 text-reset"><i class="fab fa-google"></i></a>
        <a routerLink="" class="me-4 text-reset"><i class="fab fa-instagram"></i></a>
        <a routerLink="" class="me-4 text-reset"><i class="fab fa-linkedin"></i></a>
        <a routerLink="" class="me-4 text-reset"><i class="fab fa-github"></i></a>
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="container">
      <div class="text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4"><i class="fas fa-gem me-3"></i>Bravo</h6>
            <p>Công ty Cổ phần Phần mềm BRAVO là công ty chuyên sâu phát triển và triển khai ứng dụng các hệ thống phần
              mềm về công nghệ thông tin vào quản lý sản xuất kinh doanh nhằm nâng cao hiệu quả điều hành...</p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Services</h6>
            <p><a routerLink="/" class="text-reset">Service 1</a></p>
            <p><a routerLink="/" class="text-reset">Service 2</a></p>
            <p><a routerLink="/" class="text-reset">Service 3</a></p>
            <p><a routerLink="/" class="text-reset">Service 4</a></p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Product</h6>
            <p><a routerLink="/" class="text-reset">Product 1</a></p>
            <p><a routerLink="/" class="text-reset">Product 2</a></p>
            <p><a routerLink="/" class="text-reset">Product 3</a></p>
            <p><a routerLink="/" class="text-reset">Product 4</a></p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p><i class="fas fa-building me-3"></i> Kim No, Dong Anh, Hà Nội</p>
            <p><i class="fas fa-envelope me-3"></i> contacts.codepro@gmail.com</p>
            <p><i class="fas fa-phone me-3"></i> (+84) 386.897.757</p>
            <p><i class="fas fa-fax me-3"></i> (+84) 386.897.757</p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      Copyright © 2022
      <a class="text-reset fw-bold" target="blank" href="https://code.pro.vn/"> Lê Anh Đức</a>. All rights
      reserved
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</div>
