<div class="container mt-4">
  <div *ngIf="isArticleList" class="row row-cols-1 row-cols-md-4 g-4" infinite-scroll (scrolled)="onScroll()"
    [infiniteScrollDistance]="0">
    <div class="col" *ngFor="let article of articleList">
      <div class="card h-100">
        <a href="{{article.url}}" target="_blank">
          <img src="{{ article.urlToImage }}" class="card-img-top" alt="{{ article.title }}" />
        </a>
        <div class="card-body">
          <a href="{{article.url}}" target="_blank">
            <h5 class="card-title">{{ article.title }}</h5>
          </a>
          <p class="card-text">{{ article.description }}</p>
        </div>
        <div class="card-footer">
          <small class="text-muted">{{ article.publishedAt | date:'fullDate' }}</small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="form-group text-center">
    <img style="width: 100px" src="./assets/images/ellipsis-loading.svg" alt="loading">
  </div>
</div>
