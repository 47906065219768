<div class="container mt-4">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped table-hover" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th scope='col'>STT</th>
                <th scope='col'>Id</th>
                <th scope='col'>ParentId</th>
                <th scope='col'>Name</th>
                <th scope='col'>ItemTypeName</th>
                <th scope='col'>Code</th>
                <th scope='col'>Unit</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th scope='col'>STT</th>
                <th scope='col'>Id</th>
                <th scope='col'>ParentId</th>
                <th scope='col'>Name</th>
                <th scope='col'>ItemTypeName</th>
                <th scope='col'>Code</th>
                <th scope='col'>Unit</th>
              </tr>
            </tfoot>
            <tbody *ngIf="listProduct">
              <tr *ngFor="let item of listProduct.slice(previous * perPage, page * perPage); index as i">
                <th scope='row'>{{ i + previous * perPage + 1 }}</th>
                <td>{{ item.Id }}</td>
                <td>{{ item.ParentId }}</td>
                <td>{{ item.Name }}</td>
                <td>{{ item.ItemTypeName }}</td>
                <td>{{ item.Code }}</td>
                <td>{{ item.Unit }}</td>
              </tr>
            </tbody>
          </table>
          <!-- Phân trang -->
          <nav aria-label="...">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class]="check ? 'disabled' : ''">
                <a class="page-link" [routerLink]="['/product']" [queryParams]="{page: previous}">Previous</a>
              </li>
              <li class="page-item" [class]="check ? 'disabled' : ''">
                <a class="page-link" [routerLink]="['/product']" [queryParams]="{page: previous}">{{ previous }}</a>
              </li>
              <li class="page-item active" aria-current="page">
                <a class="page-link" [routerLink]="['/product']" [queryParams]="{page: page}">{{ page }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" [routerLink]="['/product']" [queryParams]="{page: next}">{{ next }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" [routerLink]="['/product']" [queryParams]="{page: next}">Next</a>
              </li>
            </ul>
          </nav>
          <!-- Phân trang -->
        </div>
      </div>
    </div>
  </div>
</div>
