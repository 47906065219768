<div class="container mt-4">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
          <div class="form-group mt-2">
            <label for="Type">Type</label>
            <input type="text" formControlName="Type" class="form-control shadow-sm bg-white rounded"
              [ngClass]="{ 'is-invalid': submitted && form.Type.errors }" />
            <div *ngIf="submitted && form.Type.errors" class="invalid-feedback">
              <div *ngIf="form.Type.errors.required">
                Type không được bỏ trống
              </div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="SurveyCampaignId">Survey Campaign ID</label>
            <input type="text" formControlName="SurveyCampaignId" class="form-control shadow-sm bg-white rounded"
              [ngClass]="{ 'is-invalid': submitted && form.SurveyCampaignId.errors }" />
            <div *ngIf="submitted && form.SurveyCampaignId.errors" class="invalid-feedback">
              <div *ngIf="form.SurveyCampaignId.errors.required">
                ID không được bỏ trống
              </div>
            </div>
          </div>
          <div class="form-group mt-2 d-flex justify-content-end mt-3">
            <button (click)="onClean()" [disabled]="searchForm.invalid"
              class="btn btn-danger me-2 shadow-lg bg-danger rounded">Xóa</button>
            <button [disabled]="loading" class="btn btn-success shadow-lg bg-black rounded">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Tìm kiếm
            </button>
          </div>
        </form>
        <div *ngIf="loading" class="form-group text-center">
          <img style="width: 100px" src="./assets/images/ellipsis-loading.svg" alt="loading">
        </div>
        <div *ngIf="jsonData" [class]="isMessage ? 'note-danger' : 'note-success'" class="form-group note mt-4"
          [ngStyle]="{'height': isMessage ? 'auto' : 'calc(100vh - 300px)'}">
          <pre style="height: 100%"><code>{{ jsonData }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</div>
