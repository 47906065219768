<div *ngIf="isDogList" class="row row-cols-1 row-cols-md-4 g-4" infinite-scroll (scrolled)="onScroll()"
  [infiniteScrollDistance]="0">
  <div class="col" *ngFor="let dog of dogs">
    <div class="card h-100 d-flex align-items-center justify-content-center">
      <div class="bg-image hover-overlay hover-zoom ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
        <img src="{{ dog.url }}" class="w-100" />
        <a>
          <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="form-group text-center">
  <img style="width: 100px" src="./assets/images/ellipsis-loading.svg" alt="loading">
</div>
